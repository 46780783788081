import React from "react";
import "../../assets/styles/PrivacyPolicy.css";
import logo from "../../assets/images/logo-icon.png";

const PrivacyPolicy = () => {
  return (
    <div className='privacy-policy-container'>
      <header className='privacy-policy-header'>
        <img src={logo} alt='Native Power-Ups Logo' className='logo' />
        <h1 style={{ margin: 0 }}>Native Power-Ups</h1>
      </header>
      <main className='privacy-policy-content'>
        <div className='scroll-container'>
          <h2>Privacy Policy</h2>
          <p>
            <strong>Last Updated:</strong> 22nd October, 2024
          </p>

          <ol className='policy-list'>
            <li>
              <strong>General Information</strong>
              <ol>
                <li>
                  <strong>Organization Name:</strong> Native Power-Ups
                </li>
                <li>
                  <strong>Contact Information:</strong>{" "}
                  support@nativepowerups.com
                </li>
                <li>
                  <strong>Power-Up Names:</strong> Card Relationships for
                  Trello, Hierarchy for Trello.
                </li>
              </ol>
            </li>
            <li>
              <strong>Data Collection</strong>
              <ol>
                <li>
                  <strong>User Information:</strong> Native Power-Ups does not
                  collect any personal information.
                </li>
                <li>
                  <strong>Trello Data:</strong> Native Power-Ups can access
                  board, card, member, and organization information within
                  Trello. However, Native Power-Ups does not collect or store
                  any of this data outside of Trello.
                </li>
                <li>
                  <strong>Third-Party Services:</strong>
                  <ol>
                    <li>
                      <strong>Netlify:</strong> Native Power-Ups uses Netlify
                      for hosting its power-ups. Netlify hosts the applications
                      and serves them to users, but it does not directly access
                      Trello data. Any access to Trello data is handled through
                      the application code running on the client side.
                    </li>
                    <li>
                      <strong>Microsoft Clarity:</strong> Native Power-Ups
                      partners with Microsoft Clarity and Microsoft Advertising
                      to capture how users interact with the Native Power-Ups
                      website through behavioral metrics, heatmaps, and session
                      replay to improve and market Native Power-Ups
                      products/services. For more information about how
                      Microsoft collects and uses user data, visit the{" "}
                      <a href='https://privacy.microsoft.com/en-us/privacystatement'>
                        Microsoft Privacy Statement
                      </a>
                      .
                    </li>
                    <li>
                      <strong>Sentry:</strong> Native Power-Ups uses Sentry for
                      error tracking and performance monitoring. Sentry collects
                      data related to application errors, performance metrics,
                      and other diagnostics to help Native Power-Ups improve the
                      reliability and performance of its services. For more
                      information about how Sentry collects and uses user data,
                      visit the{" "}
                      <a href='https://sentry.io/privacy/'>
                        Sentry Privacy Policy
                      </a>
                      .
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Data Usage</strong>
              <ol>
                <li>
                  <strong>Purpose of Data Collection:</strong> Data is stored
                  against Trello organizations, members, boards and cards and
                  retrieved to manage card relationships and power-up settings.
                  Data collected through Microsoft Clarity is used to analyze
                  user interactions and improve the user experience. Data
                  collected through Sentry is used to track errors and monitor
                  performance to improve the reliability and performance of
                  Native Power-Ups services.
                </li>
                <li>
                  <strong>Data Sharing:</strong> Native Power-Ups does not share
                  any collected data with third parties other than Microsoft
                  Clarity and Sentry. Data collected by these services may be
                  used by them according to their privacy policies.
                </li>
                <li>
                  <strong>Data Retention:</strong> Data is retained on user
                  Trello accounts until users remove Native Power-Ups power-ups.
                  Data collected by Microsoft Clarity and Sentry is retained
                  according to their respective data retention policies.
                </li>
              </ol>
            </li>
            <li>
              <strong>User Rights</strong>
              <ol>
                <li>
                  <strong>Data Deletion:</strong> To delete their data, users
                  can remove Native Power-Ups power-ups from their Trello
                  boards. Additionally, Card Relationships for Trello users can
                  delete all card relationships by navigating to settings,
                  danger zone, then clicking "Delete all card relationships" and
                  following the prompts. For data collected by Microsoft Clarity
                  and Sentry, users can refer to their privacy policies for more
                  information on managing their data.
                </li>
              </ol>
            </li>
            <li>
              <strong>Security</strong>
              <ol>
                <li>
                  <strong>Security Measures:</strong> Since Native Power-Ups
                  does not collect or store any data outside of Trello,
                  traditional data security measures applicable to data storage
                  are not necessary. Native Power-Ups relies on Trello’s
                  security measures to protect the data stored within their
                  platform. Data collected by Microsoft Clarity and Sentry is
                  protected by their respective security measures.
                </li>
              </ol>
            </li>
            <li>
              <strong>Updates to Privacy Policy</strong>
              <ol>
                <li>
                  <strong>Policy Changes:</strong> Native Power-Ups may update
                  this privacy policy from time to time. Please check back here
                  periodically to stay informed of any changes.
                </li>
              </ol>
            </li>
            <li>
              <strong>Consent</strong>
              <ol>
                <li>
                  <strong>User Consent:</strong> By adding Native Power-Ups
                  power-ups to their Trello account, users consent to the data
                  collection and usage as described in this privacy policy.
                </li>
              </ol>
            </li>
          </ol>
          <br />
          <strong>
            If you have any questions or concerns about this privacy policy,
            please contact us at support@nativepowerups.com.
          </strong>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
